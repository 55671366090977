@import '../../assets/less/less-var.less';
@import '../../assets/less/less-func.less';
#pass_footer {
  width: 100%;
  height: auto;
  background: #fff;
  background-size: cover;
  color: #333;
  padding: 80px 120px;
  border-top: 1px solid #f0f0f0;
  .content {
    display: flex;
    justify-content: space-between;
    .content_left {
      width: 320px;
      margin-right: 80px;
      .logo {
        width: 200px;
        height: 36px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .introduce {
        margin-top: 20px;
        font-weight: normal;
        font-size: 14px;
        color: #7d7f92;
        line-height: 28px;
      }
      .button_group {
        margin-top: 20px;
        .cust-button {
          margin-right: 16px;
        }
      }
      .share_list {
        margin-top: 20px;
        img {
          width: 40px;
          height: 40px;
          margin-right: 16px;
        }
      }
    }
    .content_right {
      width: auto;
      display: flex;
      justify-content: space-between;
      .footer_menu {
        min-width: 180px;
        margin-right: 2%;
        text-align: left;
        &:last-child {
          margin-right: 0;
        }
        .menu_title {
          font-weight: bold;
          font-size: 16px;
          color: #333333;
        }
        .menu_list {
          .list {
            margin-top: 32px;
            font-weight: normal;
            font-size: 14px;
            color: #7d7f92;
            line-height: 18px;
            cursor: pointer;
            .menu_name {
              color: #333;
              &:hover {
                color: #333333;
              }
            }
            .menu_name_disabled {
              color: #7d7f92;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  #pass_footer {
    padding: 40px 16px;
    .content {
      .content_left {
        margin-right: 32px;
      }
      .content_right {
        width: 100%;
        .footer_menu {
          min-width: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #pass_footer {
    padding: 32px 16px;
    .content {
      flex-direction: column;
      .content_left {
        width: 100%;
        margin-right: 0;
      }
      .content_right {
        margin-top: 32px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  #pass_footer {
    .content {
      .content_right {
        flex-wrap: wrap;
        .footer_menu {
          min-width: 50%;
          margin-right: 0;
          margin-bottom: 20px;
          &:nth-last-child(1),
          &:nth-last-child(2) {
            margin-bottom: 0;
          }
          padding: 0 16px;
          .menu_list {
            .list {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}
