@import '../../assets/less/less-var.less';
@import '../../assets/less/less-func.less';
#pass_header {
  width: 100%;
  height: @pcHeight;
  position: fixed;
  top: 0;
  z-index: 1001;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(51, 65, 80, 0.03), 0px 2px 2px 0px rgba(51, 65, 80, 0.03);

  .header_left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      flex-shrink: 0;
      box-sizing: border-box;
      height: 40px;
      width: 180px;
      background: url('../../assets/images/common/logo.png') no-repeat center;
      background-size: 100%;
      cursor: pointer;
      position: relative;
    }
  }

  .header_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .code {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .code_list {
        width: 80px;
        line-height: 40px;
        margin-left: 12px;
        cursor: pointer;
        padding: 0 10px;
        background: #c30f23;
        color: #fff;
        border-radius: 5px;
        font-size: 14px;
        text-align: center;
      }
    }

    .register,
    .login_button {
      margin-left: 15px;
    }

    .user_center {
      height: 58px;
      padding-left: 32px;
      line-height: 66px;
      font-family: Poppins, Poppins-SemiBold;
      font-size: 14px;
      font-weight: 400;
      color: #89929d;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .header_avatar {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .user_center_down {
        margin-left: 10px;
        color: #c4ccd6;
        font-size: 12px;
      }
    }
  }
}

.user_down {
  .ant-dropdown-menu {
    padding: 10px 0;
  }

  .ant-dropdown-menu-item {
    padding: 5px 13px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;

    &:hover {
      background: #f6f6f6;
    }
  }
}

.code_tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  #pass_header {
    padding: 0 32px;
    .header_left {
      .logo {
        width: 140px;
      }
    }
    .header_right {
      flex-direction: row-reverse;
    }
  }
}

@media screen and (max-width: 750px) {
  #pass_header {
    padding: 0 10px;
    .header_left {
      .logo {
        width: 120px;
      }
    }
  }
}
