@import '../../assets/less/less-var.less';
@import '../../assets/less/less-func.less';
.aacPass-page-layout {
  transition: all 1s ease-in-out;
  .cust-content {
    box-sizing: border-box;
    margin-top: @pcHeight;
    // background: var(--main-bgc);
    min-height: @pcMinHeight;
  }

  .pt80 {
    -webkit-animation: padding-top-move linear 300ms forwards;
    animation: padding-top-move linear 300ms forwards;
  }
}
.modal_content {
  .modal_content_prompt {
      width: 100%;
      margin-top: 36px;
      font-size: 18px;
      font-family: Poppins, Poppins-SemiBold;
      font-weight: 400;
      text-align: center;
      color: #333;
      line-height: 29px;

      span {
          color: #EF3CD1;
      }
  }

  .cust-button {
      width: 100%;
      text-align: center;
      margin: 60px auto 36px;
  }
}
@media only screen and(max-width:1110px) {
  .aacPass-page-layout{
    .cust-content {
      box-sizing: border-box;
      margin-top: 66px;
      // background: var(--main-bgc);
      min-height: @mobileMinHeight;
    }
  }
}