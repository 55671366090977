@import '../../assets/less/less-var.less';
@import '../../assets/less/less-func.less';
.language_select {
  position: relative;

  .language_input {
    height: @pcHeight;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .pc-language-icon {
      font-size: 32px;
    }
  }

  .mobile-language-icon {
    padding: 20px 10px;
    font-size: 32px;
    display: none;
  }

  .language-container {
    overflow: hidden;
    position: absolute;
    right: -38px;
    z-index: 99;
    width: 180px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    background: #f9f9f9;

    .language-item {
      padding: 19px 10px;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #666666;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: #eeeeee;
      }
    }

    .isActive {
      background: #eeeeee;
    }
  }
}

@media only screen and(max-width:1200px) {
  .language_select {
    .language-container {
      right: -55px;
      overflow: hidden;
    }

    .language_input {
      display: none;
    }

    .mobile-language-icon {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
