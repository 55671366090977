@import '../../assets/less/less-var.less';
@import '../../assets/less/less-func.less';
.mobile-menu-container {
  padding: 20px 10px;
  display: none;
  .menu-icon {
    font-size: 24px;
    color: #333;
  }
}

.pc-menu-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  .pc-menu-item {
    position: relative;
    box-sizing: border-box;
    margin: 0 15px;
    line-height: 66px;
    flex-shrink: 0;
    text-align: center;
    font-family: Poppins, Poppins-SemiBold;
    font-size: 14px;
    font-weight: 400;
    color: #89929d;
    min-width: 80px;
    .item-title {
      position: relative;
    }
    &:hover {
      font-size: 14px;
      font-family: Poppins, Poppins-SemiBold;
      font-weight: 500;
      color: var(--main-fontc);
    }

    .menu-item-container {
      position: absolute;
      top: 66px;
      left: -30px;
      min-width: 158px;
      background: #23262f;
      border-radius: 8px;
      box-shadow: 0px 2px 32px 0px #171d36;

      .menu-item-content {
        color: #9fa7b3;
        line-height: 26px;
        box-sizing: border-box;
        padding: 14px 26px;

        &:hover {
          color: var(--main-fontc);
        }
      }

      .inft-menu-active {
        color: var(--main-fontc);
      }
    }
  }

  .inft-menu-active {
    position: relative;
    font-family: Poppins, Poppins-SemiBold;
    font-size: 14px;
    font-weight: 500;
    color: var(--main-fontc);
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      background: #c30f23;
      border-radius: 2px;
    }
  }
  .inft-menu-disabled {
    cursor: not-allowed;
    &:hover {
      color: #89929d;
      font-weight: 400;
    }
  }
}

.mobile-drawer-container {
  .ant-drawer-mask {
    background: rgba(51, 51, 51, 0.16);
    backdrop-filter: blur(3px);
  }

  .ant-drawer-content-wrapper {
    top: 66px !important;
    width: 100% !important;

    .ant-drawer-content {
      background: #f9f9f9;
      border-radius: 0 0 24px 24px !important;
      height: auto;
      .ant-drawer-body {
        padding: 36px 20px;

        .mobile-cust-menu-container {
          background: transparent;
          border: none;

          &:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: transparent;
          }

          .ant-menu-item {
            padding: 0 !important;

            &:not(:last-child) {
              margin-bottom: 16px;
            }

            &::after {
              display: none;
            }

            .ant-menu-item-icon {
              font-size: 18px;
            }

            .ant-menu-title-content {
              margin-left: 16px;
              font-size: 16px;
              font-weight: 500;
            }
          }

          .ant-menu-submenu {
            .ant-menu-submenu-title {
              padding: 0 !important;

              &:active {
                background: transparent;
              }

              .ant-menu-item-icon {
                font-size: 18px;
              }

              .ant-menu-title-content {
                margin-left: 16px;
                font-size: 16px;
                font-weight: 500;
              }

              .ant-menu-submenu-arrow {
                right: 4px;
                color: #fff;
              }
            }

            .ant-menu-sub {
              box-sizing: border-box;
              background: #2b2e38;
              border-radius: 10px;

              .ant-menu-item {
                margin: 20px 34px;

                .ant-menu-title-content {
                  margin-left: 0;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .mobile-menu-connect-button {
          margin-top: 50px;
          text-align: center;

          .button-container {
            .button-text {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width:1200px) {
  .mobile-menu-container {
    display: flex;
  }
  .pc-menu-container {
    display: none;
  }
  .mobile-drawer-container {
    .ant-drawer-content-wrapper {
      top: 66px !important;
      box-shadow: none !important;
      .ant-drawer-content {
        .ant-drawer-body {
          padding: 20px 10px;
        }
      }
    }
  }
}
