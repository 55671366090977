@minWidth: 1200px;
@pcHeight: 66px;
@pcFooterHeight: 300px;
@mobileFooterHeight: 340px;
@mobileHeight: 80px;
@pcMinHeight: calc(100vh - @pcHeight - @pcFooterHeight);
@mobileMinHeight: calc(100vh - @mobileHeight - @mobileFooterHeight -20px);
@typeCenter: 1200px;

@keyframes inft-space-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}

.global-skeleton-loading {
  width: 100%;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.2) 25%,
    rgba(129, 129, 129, 0.24) 37%,
    rgba(190, 190, 190, 0.2) 63%
  );
  background-size: 400% 100%;

  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: inft-space-skeleton-loading;
}
.hover-up-animation {
  transform: translateY(0px);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translateY(-4px);
    transition: all 0.3s ease-in-out;
  }
}
