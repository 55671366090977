@import '../assets/less/less-var.less';
@import '../assets/less/less-func.less';
@import './reset.less'; // reset.less
@import (inline) './font.css'; // 全局字体
@import (inline) './animation.css'; // 全局动画
@import './global.less'; // global.less
@import '~antd/dist/antd.css'; // 引入官方提供的 less 样式入口文件
@import '~nprogress/nprogress.css'; // 全局 请求loading样式

html,
body {
  font-family: 'PingFangSC-Medium, PingFang SC';
  font-weight: normal;
  color: var(--main-fontc);
  background: #fff;
  background-position: 50%;
  width: 100% !important;
  // 适配是删除最小宽
  // min-width: 1400px;
}

.ant-layout {
  background: none;
}

//隐藏全局滚动条
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  width: 0;
  height: 100%;
}

.ant-notification-topRight {
  z-index: 1001 !important;

  .cust-notification {
    padding: 18px 20px;
    margin: 0;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 10px;

    .ant-notification-notice-message {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #333;
      line-height: 18px;

      .cust-notification-content {
        display: flex;
        align-items: center;

        .notification-icon {
          font-size: 20px;
        }

        .notification-title {
          margin-left: 10px;
        }
      }
    }

    .ant-notification-notice-description {
      display: none;
    }
  }
}

// checkbox
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #c30f23;
  border: 1px #c30f23 solid;
}

.ant-checkbox-checked::after {
  border: 1px #c30f23 solid;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px #d9d9d9 solid !important;
}

.transfer_modal {
  width: 684px !important;

  .ant-modal-content {
    .ant-modal-body {
      min-height: 286px;
    }
  }
}

.f-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.f-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
